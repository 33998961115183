import React from 'react';
import * as styles from './ViewAllButton.module.scss';

export const ViewAllButton = ({ resultsArray, view, setView, viewAllText }) => (
  <div className={styles.viewAllButton}>
    <p className={styles.viewAllText}>
      {resultsArray.length} {viewAllText} found
    </p>
    <button type="button" className={`button primary ${styles.viewMore}`} onClick={() => setView(view)}>
      View All
    </button>
  </div>
);
