import React, { useState, useEffect, useRef } from 'react';
import { graphql } from 'gatsby';
import { Layout, Loading } from 'components';
import { algoliaKeys, defineQueries } from '../utils/algoliaUtils';
import { LoadedResults } from '../components/SearchComponent/LoadedResults';
// import * as styles from './searchPage.module.scss';

const SearchPage = ({ location, data: staticData }) => {
  // Component states
  const [dealsSearchResults, setDealsSearchResults] = useState([]);
  const [blogsSearchResults, setBlogsSearchResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [view, setView] = useState('all');
  const warningStatements = staticData.prismicSettings.data.warning_statements;

  // scroll to top when you click 'view all ...'
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [view]);

  // Search params extracted from the url
  const searchParams = location?.search
    ?.split('=')
    ?.filter(word => word !== '?search')[0]
    ?.split('%20')
    ?.join(' ');

  // Algolia settings
  const client = algoliaKeys();
  const queries = defineQueries(searchParams);

  // This useEffect fuction make a call to the Algolia API and sets search results with search params
  useEffect(() => {
    setLoading(true);
    const findHits = async () => {
      // API call that performs the queries - https://www.algolia.com/doc/api-reference/api-methods/multiple-queries/
      client.multipleQueries(queries).then(({ results }) => {
        const combinedResults = [];
        combinedResults.push(results[0].hits);
        combinedResults.push(results[1].hits);

        setDealsSearchResults(results[0].hits);
        setBlogsSearchResults(results[1].hits);
        setLoading(false);
      });
    };
    findHits();
  }, [searchParams]);

  // designing the way we want to pass data to the TileGrid component and display deal tiles
  // Only display 'Public' deals
  const refactoredDealSearchResults = dealsSearchResults
    .filter(result => result.visibility === 'Public' && result.hideOnProduction !== true)
    .map(result => {
      const newObj = {
        tile: {
          document: {
            data: { ...result },
            id: result?.id,
            prismicId: result?.prismicId,
            type: result?.type,
            uid: result?.uid,
          },
        },
      };
      return newObj;
    });

  // This section filters out EOI deals found in the search results, if a deal also has both an EOI page and a Live Deal page present. If you want to see both EOI and Live Deal tiles in the search results page, Just replace 'eoiFilteredRefactoredDealSearchResults' with 'refactoredDealSearchResults' in the jsx.
  // ==============================================================================================================================
  // making a copy of the 'refactoredDealSearchResults' array and only getting the relevant fields for finding any deals that both have an EOI and offer document
  const trimmedRefactoredDealSearchResults = refactoredDealSearchResults.map(deal => ({
    title: deal.tile.document.data.companyName.text,
    isEoi: deal.tile.document.data.isEoi,
    uid: deal.tile.document.uid,
  }));

  // making a copy of the 'refactoredDealSearchResults' array and only getting the names of deals
  const justNamesOfDeals = refactoredDealSearchResults.map(deals => deals.tile.document.data.companyName.text);

  // Finding all EOI documents in the trimmedRefactoredDealSearchResults array
  const eois = trimmedRefactoredDealSearchResults.filter(deal => deal.isEoi);

  // Finding out the occurences in the 'justNamesOfDeals' of the names of eoi deals
  const eoiOccurences = eois.map(eoi => [eoi, justNamesOfDeals.filter(deal => deal === eoi.title).length]);

  // Filtering eois that have their title occur more than once in the 'justNamesOfDeals' array
  const duplicateDeals = eoiOccurences
    .filter(x => x[1] > 1)
    .map(y => y[0])
    .map(deal => deal.uid);

  // If a deal has both EOI and offer room documents, do not display the EOI tile in the search results page
  const eoiFilteredRefactoredDealSearchResults = refactoredDealSearchResults.filter(
    deal => !duplicateDeals.includes(deal.tile.document.uid)
  );
  // ==============================================================================================================================

  // designing the way we want to pass data to the TileGrid component and display blog tiles
  const refactoredBlogSearchResults = blogsSearchResults.map(result => {
    const newObj = {
      tile: {
        document: {
          data: { ...result },
          id: result?.id,
          prismicId: result?.prismicId,
          type: result?.type,
          uid: result?.uid,
        },
      },
    };
    return newObj;
  });

  // what to display in different headers
  const sliceHeaderInfo = {
    allTagline: { text: 'SEARCH RESULTS FOR:' },
    dealTagline: { text: 'VIEW ALL DEALS FOR:' },
    blogTagline: { text: 'VIEW ALL BLOGS FOR:' },
    allDealTitle: { text: 'Deals' },
    allBlogTitle: { text: 'Blog Articles' },
    title: { text: `“${searchParams.toUpperCase()}”` },
  };

  return (
    <Layout location={location} bodyClassName="search-page">
      {loading ? (
        <Loading fullScreen />
      ) : (
        <LoadedResults
          eoiFilteredRefactoredDealSearchResults={eoiFilteredRefactoredDealSearchResults}
          refactoredBlogSearchResults={refactoredBlogSearchResults}
          warningStatements={warningStatements}
          sliceHeaderInfo={sliceHeaderInfo}
          setView={setView}
          view={view}
        />
      )}
    </Layout>
  );
};

export default SearchPage;

export const pageQuery = graphql`
  {
    prismicSearchPage {
      uid
    }
    prismicSettings {
      data {
        warning_statements {
          country
          statement {
            html
          }
          title {
            html
          }
          tooltip {
            html
          }
        }
      }
    }
  }
`;
