import React from 'react';
import { TileGrid } from '../TileGrid';
import { SliceHeader } from '../Page/SliceHeader';
import { ViewAllButton } from './ViewAllButton';
import * as styles from './LoadedResults.module.scss';

export const LoadedResults = ({
  eoiFilteredRefactoredDealSearchResults,
  refactoredBlogSearchResults,
  warningStatements,
  sliceHeaderInfo,
  setView,
  view,
}) => {
  const viewIsAll = view === 'all';
  const viewIsDeals = view === 'deals';
  const viewIsBlogs = view === 'blogs';

  const inheritedClassnames = {
    dealGridContainer: styles.dealGridContainer,
    dealGrid: styles.dealGrid,
    pageSliceTitle: styles.pageSliceTitle,
  };

  return (
    <>
      {/* if the view state is set to 'all', display both deals and blogs */}
      {viewIsAll && (
        <>
          <div>
            <SliceHeader
              className={styles.resultsHeader}
              titleName={styles.pageSliceTitle}
              tagline={sliceHeaderInfo.allTagline}
              title={sliceHeaderInfo.title}
            />
          </div>
          {eoiFilteredRefactoredDealSearchResults.length >= 1 && (
            <div>
              <TileGrid
                tiles={eoiFilteredRefactoredDealSearchResults.slice(0, 3)}
                inheritedClassnames={inheritedClassnames}
                title={sliceHeaderInfo.allDealTitle}
                warningStatements={warningStatements}
              />
              {eoiFilteredRefactoredDealSearchResults.length > 3 && (
                <ViewAllButton
                  resultsArray={eoiFilteredRefactoredDealSearchResults}
                  view="deals"
                  setView={setView}
                  viewAllText="deals"
                />
              )}
            </div>
          )}
          {refactoredBlogSearchResults.length >= 1 && (
            <div>
              <TileGrid
                tiles={refactoredBlogSearchResults.slice(0, 3)}
                title={sliceHeaderInfo.allBlogTitle}
                inheritedClassnames={inheritedClassnames}
              />
              {refactoredBlogSearchResults.length > 3 && (
                <ViewAllButton
                  resultsArray={refactoredBlogSearchResults}
                  view="blogs"
                  setView={setView}
                  viewAllText="blog articles"
                />
              )}
            </div>
          )}
        </>
      )}

      {/* if the view state is set to 'deals', display all deals */}
      {viewIsDeals && (
        <div className={styles.singleResults}>
          <TileGrid
            tiles={eoiFilteredRefactoredDealSearchResults}
            tagline={sliceHeaderInfo.dealTagline}
            title={sliceHeaderInfo.title}
            backLink
            setView={setView}
            inheritedClassnames={inheritedClassnames}
            warningStatements={warningStatements}
          />
        </div>
      )}

      {/* if the view state is set to 'blogs', display all blogs */}
      {viewIsBlogs && (
        <div className={styles.singleResults}>
          <TileGrid
            tiles={refactoredBlogSearchResults}
            tagline={sliceHeaderInfo.blogTagline}
            title={sliceHeaderInfo.title}
            backLink
            setView={setView}
            inheritedClassnames={inheritedClassnames}
          />
        </div>
      )}
    </>
  );
};
